<template>
  <transition name="fade">
    <div
      v-if="visible"
      role="alert"
      :class="[
        'alert',
        `alert-type-${type}`,
        `alert-size-${size}`,
        `alert-align-${align}`,
        roundBorders && 'alert-round-borders',
        margin && 'alert-margin',
      ]"
    >
      <div v-if="$slots.icon" class="alert-icon-wrap">
        <slot name="icon" />
      </div>
      <strong v-if="title" class="title">{{ title }}</strong>
      <div
        v-if="$slots.default"
        :class="['text-wrap', textBlock && 'text-block']"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="text()" />
      </div>
      <span class="alert-action">
        <slot name="action" />
      </span>
      <button
        v-if="dismissible"
        aria-label="Dismiss this Alert"
        class="close-button"
        @click="close"
      >
        <SvgIcon icon="x" class="close-icon" />
      </button>
    </div>
  </transition>
</template>

<script setup lang="ts">
  import { useSlots } from "vue"

  const slots = useSlots()
  interface Props {
    title?: string
    type?: "info" | "error" | "success" | "warning" | "dark"
    size?: "normal" | "small" | "large"
    align?: "left" | "center"
    dismissible?: boolean
    textBlock?: boolean
    roundBorders?: boolean
    margin?: boolean
  }

  withDefaults(defineProps<Props>(), {
    title: "",
    type: "info",
    size: "normal",
    align: "center",
    dismissible: false,
    textBlock: false,
    roundBorders: true,
    margin: true,
  })

  const visible = ref(true)

  const close = () => {
    visible.value = false
  }

  const text = () => {
    return slots.default ? slots.default()[0].children : ""
  }
</script>

<style lang="scss">
  .alert {
    position: relative;
    padding: $base-spacing * 4.5;
    background: $gray-tint;
    color: $gray-text;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &-size-small {
      padding: $base-spacing * 3.5;
    }
    &-size-large {
      padding: $base-spacing * 5 $base-spacing * 3;
      @include type("sm");
    }
    &-align-left {
      text-align: left;
      justify-content: flex-start;
    }
    &-align-center {
      text-align: center;
      justify-content: center;
    }
    &-round-borders {
      border-radius: $border-radius;
    }
    &-margin {
      margin: $base-spacing * 3 0;
    }

    .text-wrap {
      &.text-block {
        width: 100%;
        margin-left: 0;
      }

      &:deep(p) {
        margin: 0;
      }
    }
    &.alert-type-info {
      background: $blue-tint;
      color: $blue-text;

      .close-button {
        background: $blue-text;
        color: $blue-tint;
      }
    }
    &.alert-type-success {
      background: $green-tint;
      color: $green-text;

      .close-button {
        color: $green-tint;
        background: $green-text;
      }
    }
    &.alert-type-warning {
      background: $yellow-tint;
      color: $yellow-text;

      .close-button {
        color: $yellow-tint;
        background: $yellow-text;
      }
    }
    &.alert-type-error {
      background: $red-tint;
      color: $red-text;

      a {
        color: inherit;
      }
      .close-button {
        color: $red-tint;
        background: $red-text;
      }
    }
    &.alert-type-dark {
      background: $navy-500;
      color: white;

      .close-button {
        color: $navy-500;
        background: $navy-tint;
      }
    }

    .alert-icon-wrap {
      display: inline-flex;
      align-items: center;
      margin-right: $base-spacing * 2;
      color: currentColor;

      svg {
        width: $base-spacing * 6;
        height: $base-spacing * 6;
      }
    }

    .alert-action {
      margin-top: $space-s;
      display: inline-block;
      a {
        color: currentColor;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
    .alert-action:empty {
      margin-top: 0;
    }

    .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      appearance: none;
      position: absolute;
      border-radius: $base-spacing * 6;
      border: 0 none;
      padding: 0;
      top: $base-spacing * -2.5;
      right: $base-spacing * -2.5;
      width: $base-spacing * 6;
      height: $base-spacing * 6;
      cursor: pointer;
    }

    .close-icon {
      width: $base-spacing * 4;
      height: $base-spacing * 4;
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  }
</style>
